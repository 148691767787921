import { useState, useEffect } from 'react';
import { getAnswerPattern } from '../utils/api'; // Import the API function

export const useAnswerPattern = (country, areaCode, riskCategory, riskLevel) => {
    const [answerPattern, setAnswerPattern] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      console.log("Fetching answer pattern with params:", country, areaCode, riskCategory, riskLevel); // Debug log
  
      const fetchAnswerPattern = async () => {
        if (country && areaCode && riskLevel) { // Ensure none of these are null/undefined
          setLoading(true);
          setError(null);
          try {
            // Fetch the answer pattern from the backend
            const result = await getAnswerPattern(country, areaCode, riskCategory, riskLevel);
            console.log("Received result from backend:", result); // Debug log
            
            // Process result
            if (result.message === "No data available") {
              setError(result.message);
              setAnswerPattern([]);
            } else {
              setAnswerPattern(result.pattern || []);
            }
          } catch (err) {
            console.error("Error fetching answer pattern:", err);
            setError('Failed to fetch answer pattern');
            setAnswerPattern([]);
          } finally {
            setLoading(false);
          }
        } else {
          // Reset if conditions are not met
          setAnswerPattern([]);
        }
      };
  
      fetchAnswerPattern();
    }, [country, areaCode, riskCategory, riskLevel]);
  
    return { answerPattern, loading, error };
  };
  