import React, {useEffect, useState} from 'react';
import { LdsBackToTop, LdsBadge, LdsTabPanel, LdsTabs, LdsButton} from '@elilillyco/ux-lds-react';
import '../custom/styles/Pattern.css';
import jsPDF from 'jspdf';

import { useFetchFile } from '../hooks/useFetchFile';
import { useDataFilter } from '../hooks/useDataFilter';
import { useRowCount } from '../hooks/useRowCount';
import { useAnswerPattern } from '../hooks/useAnswerPattern';

import SelectDrop from './select/SelectDrop';
import Loading from './Loading';

function Pattern() {
  // Fetch file data only once
  const { data, fileName, loading, error } = useFetchFile('data/transformed/transformed_');

  // Filter data once fetched
  const { filters, handleFilterChange, handleRiskLevelChange, clearSelections, setData, uniqueError, filteredData, riskLevelFilters} = useDataFilter();

  // Extract selected filter values
  const selectedCountry = filters.find(filter => filter.name === 'Engagement Service Location')?.selectedValue || '';
  const selectedAreaCode = filters.find(filter => filter.name === 'Commodity Codes')?.selectedValue || '';

  // Fetch row counts based on filtered data
  const { rowCount, rowCountLevel1, error: rowCountError } = useRowCount(selectedCountry, selectedAreaCode);

  // Calculate the percentage of rowCountLevel1 relative to rowCount
  const level1Percentage = rowCount > 0 ? ((rowCountLevel1 / rowCount) * 100).toFixed(2) : 0;

  const [tabRiskCounts, setTabRiskCounts] = useState({});
  const [currentTab, setCurrentTab] = useState(0);

  // Define tab names and their respective risk columns
  const tabDetails = [
    { id: 0, label: 'Anti-Corruption', riskColumn: 'AC Risk Level' },
    { id: 1, label: 'Privacy', riskColumn: 'Privacy Risk Level' },
    { id: 2, label: 'Information Security', riskColumn: 'IS Risk Level' },
    { id: 3, label: 'Animal Welfare', riskColumn: 'AW Risk Level' },
    { id: 4, label: 'Business Continuity', riskColumn: 'BC Risk Level' }
  ];

  // Risk Level Options
  const riskLevelOptions = ['Level 1 (Limited)', 'Level 2 (Low)', 'Level 3 (Medium)', 'Level 4 (High)', 'Level 5 (Significant)'];
  // Track answer patterns for all tabs
  const [allAnswerPatterns, setAllAnswerPatterns] = useState({});

  // Fetch and update the answer pattern when a risk level is selected
   const tab = tabDetails[currentTab];
   const riskColumn = tab.riskColumn;
   const selectedRiskLevel = riskLevelFilters[riskColumn];

   const { answerPattern, loading: patternLoading, error: patternError } = useAnswerPattern(
    selectedCountry,
    selectedAreaCode,
    riskColumn,
    selectedRiskLevel
  );

  // Update the allAnswerPatterns state whenever answerPattern changes
  useEffect(() => {
    if (selectedRiskLevel && answerPattern.length > 0) {
      setAllAnswerPatterns(prevPatterns => ({
        ...prevPatterns,
        [riskColumn]: { label: tab.label, riskLevel: selectedRiskLevel, patterns: answerPattern }
      }));
    }
  }, [answerPattern, selectedRiskLevel, riskColumn, tab.label]);


  // Set the fetched data into the filter hook's state
  useEffect(() => {
    if (data.length) {
      setData(data);
    }
  }, [data, setData]);
  
  // Update the active tab's total count when `filteredData` or risk level for the active tab changes
  useEffect(() => {
    const tab = tabDetails[currentTab];
    const riskColumn = tab.riskColumn;
    const selectedRiskLevel = riskLevelFilters[riskColumn];

    // Calculate the count based on selected risk level for the active tab
    let count = 0;
    if (selectedRiskLevel) {
      count = filteredData.filter(row => row[riskColumn] === selectedRiskLevel).length;
    } else {
      // If no specific risk level is selected, show the count of all filteredData for the active tab
      count = filteredData.length;
    }

    // Update the state with the count for the active tab
    setTabRiskCounts(prevCounts => ({
      ...prevCounts,
      [currentTab]: count
    }));
  }, [filteredData, riskLevelFilters, currentTab]);
   // Handle all loading and error states in one place
   if (loading) return <h3><Loading /></h3>;
  //  if (loading || uniqueLoading || rowCountLoading) return <p>Loading...</p>;
   if (error || uniqueError || rowCountError) return <p className="error-message">{error || uniqueError || rowCountError}</p>;

   // Function to handle downloading the selected patterns as a PDF
   const handleDownloadPDF = () => {
    const doc = new jsPDF();
    let yPosition = 10; // Starting y position in the PDF
    const margin = 10; // Margin from the left edge
    const lineHeight = 10; // Space between lines

    // Add a title to the PDF
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    doc.text('Answer Patterns Report', margin, yPosition);
    yPosition += 15;

    // Draw a horizontal line below the title
    doc.setLineWidth(0.5);
    doc.line(margin, yPosition, doc.internal.pageSize.getWidth() - margin, yPosition);
    yPosition += 10;

    // Collect data for all tabs with selections
    Object.keys(allAnswerPatterns).forEach((key) => {
        const tabData = allAnswerPatterns[key];

        if (tabData && tabData.patterns.length > 0) {
            // Add the tab section title
            doc.setFontSize(16);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(40, 40, 40);
            doc.text(`${tabData.label} Risk Level: ${tabData.riskLevel}`, margin, yPosition);
            yPosition += lineHeight;

            // Draw a line below the section header
            doc.setLineWidth(0.3);
            doc.line(margin, yPosition, doc.internal.pageSize.getWidth() - margin, yPosition);
            yPosition += 5;

            // Add the answer pattern items
            tabData.patterns.forEach((item) => {
                // Add a bullet point for each item
                doc.setFontSize(12);
                doc.setFont('helvetica', 'normal');
                doc.setTextColor(60, 60, 60);
                doc.text(`• ${item}`, margin + 5, yPosition); // Add a small left indent
                yPosition += lineHeight;

                // Check if we need a page break
                if (yPosition > doc.internal.pageSize.getHeight() - 20) {
                    doc.addPage();
                    yPosition = margin;
                }
            });

            // Add some space between different tab sections
            yPosition += 10;
        }
    });

    // Save the PDF
    doc.save('answer_patterns_report.pdf');
  };

  return (
    <div>
      <div className="data-display">
        <div className='dashboard-wrapper'>
            <div className="dashboard-header">
              <LdsBadge outlined>{fileName}</LdsBadge>
              <h2>IRQ Answer Pattern: {selectedCountry || 'Select Country'}, {selectedAreaCode || 'Code'}</h2>
            </div>
            <div className="filters">
            {filters.map((filter, index) => (
              <SelectDrop
                key={index}
                label={filter.label}
                options={filter.options}
                selectedValue={filter.selectedValue}
                onChange={(e) => handleFilterChange(filter.name, e.target.value)}
                clearSelection={() => clearSelections(filter.name)}
              />
            ))}
            <div className="badge-container">
              <h4>Total Count: <LdsBadge>{rowCount}</LdsBadge></h4>
              <h4>Total Count (All Level 1's): <LdsBadge>{rowCountLevel1} ({level1Percentage}%)</LdsBadge> </h4>
            </div>
            </div>
            <br />
            <div className="tabs">
            <LdsTabs
              id="tabGroup"
              activeTab={currentTab}
              onChange={(newIndex) => setCurrentTab(newIndex)}
              tabLabels={tabDetails.map(tab => ({ tabId: tab.id, label: tab.label }))}>
              {tabDetails.map(tab => (
                <LdsTabPanel key={tab.id} tabId={tab.id} activeTab={currentTab}>
                  {/* <div className="badge-container">
                    <h4>Total Count for {tab.label}: <LdsBadge>{tabRiskCounts[currentTab] || 0}</LdsBadge></h4>
                  </div> */}
                  <SelectDrop
                    label={`${tab.label} Risk Level`}
                    options={riskLevelOptions}
                    selectedValue={riskLevelFilters[tab.riskColumn]}
                    onChange={(e) => handleRiskLevelChange(tab.riskColumn, e.target.value)}
                    clearSelection={() => handleRiskLevelChange(tab.riskColumn, '')}
                  />
                  {/* Display the answer pattern */}
                  <div className="answer-pattern-container">
                    <h4>Answer Pattern for {tab.label}:</h4>
                    {patternLoading && <p>Loading pattern...</p>}
                    {patternError && <p className="error-message">{patternError}</p>}
                    {!patternError && (
                      <ul>
                        {answerPattern.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    )}
                </div>
                </LdsTabPanel>
              ))}
                <LdsButton className="download-button" onClick={handleDownloadPDF}>
                  Download IRQ Answer Patterns as PDF
                </LdsButton>
            </LdsTabs>
            </div>
        </div>
        </div>
        <LdsBackToTop hideText />
    </div>
  );
}

export default Pattern;